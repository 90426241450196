<template>
  <div class="percentage">
    <section class="table-search">
      <div class="search-L">
        <el-select
          v-model="tableParams.merchantId"
          placeholder="请选择商户"
          @change="onMerchantSel"
          clearable
          class="mr20"
        >
          <el-option
            v-for="item in commercialList"
            :key="item.merchantId"
            :label="item.merchantName"
            :value="item.merchantId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="tableParams.shopId"
          placeholder="请选择店铺"
          clearable
          @change="onShopSel"
        >
          <el-option
            v-for="item in tableShopList"
            :key="item.shopId"
            :label="item.shopName"
            :value="item.shopId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="goAddSharePeople()"
          >添加品牌商户分账方</el-button
        >
        <el-button type="primary" @click="getSplitRatioSettingList()"
          >抽成设置</el-button
        >
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column
        label="分账接收方名称"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.brandMerchantName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="是否开启抽成"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.splitEnable ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="位置订单" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{
            scope.row.roomRatio && scope.row.roomRatio >= 0
              ? scope.row.roomRatio + "%"
              : "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券订单" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{
            scope.row.cardRatio && scope.row.cardRatio >= 0
              ? scope.row.cardRatio + "%"
              : "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品订单" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{
            scope.row.goodsRatio && scope.row.goodsRatio >= 0
              ? scope.row.goodsRatio + "%"
              : "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="团建订单" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{
            scope.row.groupRatio && scope.row.groupRatio >= 0
              ? scope.row.groupRatio + "%"
              : "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="goRemove(scope.row)"
            >移除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!--添加品牌商户分账方-->
    <el-dialog
      title="添加品牌商户分账方"
      :visible.sync="isAddShare"
      width="30%"
    >
      <el-form :model="form" :rules="rules" ref="form-ref">
        <!-- 商户名称 -->
        <el-form-item
          label="选择品牌商户"
          label-width="120px"
          prop="brandMerchantId"
        >
          <el-select
            v-model="form.brandMerchantId"
            placeholder="请选择品牌商户"
            clearable
            class="mr20"
          >
            <el-option
              v-for="item in brandMerchantList"
              :key="item.brandMerchantId"
              :label="item.brandMerchantName"
              :value="item.brandMerchantId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isAddShare = false">取 消</el-button>
        <el-button type="primary" @click="goConfrimAddMerchant('form-ref')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--抽成设置-->
    <el-dialog title="抽成设置" :visible.sync="isSetting" width="1100px">
      <el-table
        :data="itemData"
        border
        stripe
        :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }"
      >
        <el-table-column
          label="分账接收方名称"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.brandMerchantName || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="是否开启抽成"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch v-model="scope.row.splitEnable"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="位置订单" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.roomRatio"
              placeholder="请输入分账比例"
            >
              <template slot="append">%</template></el-input
            >
          </template>
        </el-table-column>
        <el-table-column label="商品订单" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.goodsRatio"
              placeholder="请输入分账比例"
              ><template slot="append">%</template></el-input
            >
          </template>
        </el-table-column>
        <el-table-column label="卡券订单" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.cardRatio"
              placeholder="请输入分账比例"
              ><template slot="append">%</template></el-input
            >
          </template>
        </el-table-column>
        <el-table-column label="团建订单" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input
              type="number"
              v-model="scope.row.groupRatio"
              placeholder="请输入分账比例"
              ><template slot="append">%</template></el-input
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <div>
          <el-button @click="isSetting = false">取 消</el-button>
          <el-button type="primary" @click="goSaveSplitRatioSetting()"
            >确 定</el-button
          >
        </div>
        <div class="tips">tips：所有的项目抽成比例必须等于100%才可进行保存</div>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { getAutoCommercialList } from "@/api/commercial/commercial";
import { getIdShopList } from "@/api/commercial/shop";
import { getAllBrandMerchantList } from "@/api/brand/merchant.js";
import {
  getSplitRatioList,
  goSaveSplitRatio,
  goRemoveSplitRatio,
  getSplitRatioSettingList,
  goSaveSplitRatioSetting,
} from "@/api/share/percentage.js";
import { rules } from "@/db/rules";

export default {
  data() {
    return {
      rules,
      commercialList: [], // 商户列表
      tableShopList: [], //店铺列表
      // 列表
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
      },
      isAddShare: false,
      form: {
        brandMerchantId: "",
      },
      isSetting: false,
      itemData: [],
      brandMerchantList: [],
    };
  },
  mounted() {
    this.getAutoCommercialList(); // 【请求】商户下拉列表
  },
  methods: {
    // 保存抽成设置
    goSaveSplitRatioSetting() {
      goSaveSplitRatioSetting(this.itemData).then((res) => {
        if (res.isSuccess == "yes") {
          this.isSetting = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getSplitRatioList();
        }
      });
    },
    // 抽成设置
    getSplitRatioSettingList() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
      };
      getSplitRatioSettingList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.itemData = res.data;
          this.isSetting = true;
        }
      });
    },
    // 移除分账方
    goRemove(row) {
      let params = {
        splitId: row.splitId,
      };
      this.$confirm("是否确定移除该分账方?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        goRemoveSplitRatio(params).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.getSplitRatioList();
            this.getAllBrandMerchantList();
          }
        });
      });
    },
    // 查询分账比例列表
    getSplitRatioList() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        page: this.tableParams.page,
        pageSize: this.tableParams.size,
      };
      getSplitRatioList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      });
    },
    // 保存分账比例列表
    goSaveSplitRatio() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        brandMerchantId: this.form.brandMerchantId,
      };
      goSaveSplitRatio(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.isAddShare = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getSplitRatioList();
          this.getAllBrandMerchantList();
        }
      });
    },
    // 查询所有品牌商户
    getAllBrandMerchantList() {
      let data = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
      };
      getAllBrandMerchantList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.brandMerchantList = res.data;
        }
      });
    },
    // 【监听】表单提交
    goConfrimAddMerchant(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.goSaveSplitRatio();
        } else {
          return false;
        }
      });
    },
    // 打开品牌商户分账方
    goAddSharePeople() {
      this.form.brandMerchantId = "";
      this.isAddShare = true;
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },
    // 【请求】商户下拉列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        if (res.isSuccess == "yes") {
          this.commercialList = res.data;
          if (res.data && res.data.length > 0) {
            this.tableParams.merchantId = res.data[0].merchantId;
            this.getTableIdShopList();
          }
        }
      });
    },

    // 【请求】筛选商户店铺列表
    getTableIdShopList() {
      let data = {
        merchantId: this.tableParams.merchantId,
      };
      getIdShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableShopList = res.data;
          if (res.data.length > 0) {
            this.tableParams.shopId = res.data[0].shopId;
            this.getAllBrandMerchantList();
            this.getSplitRatioList();
          }
        }
      });
    },

    // 【监听】筛选商户
    onMerchantSel() {
      this.tableParams.shopId = "";
      if (this.tableParams.merchantId) {
        this.getTableIdShopList();
      } else {
        this.tableShopList = [];
        this.brandMerchantList = [];
      }
    },

    // 【监听】筛选店铺
    onShopSel() {
      if (this.tableParams.shopId) {
        this.reload();
      } else {
        this.brandMerchantList = [];
      }
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getSplitRatioList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getSplitRatioList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.percentage {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
.templateList {
  display: flex;
  justify-content: space-around;

  margin-top: 20px;
  .templateItem {
    width: 250px;
    .txt {
      height: 30px;
    }

    .img {
      width: 250px;
      height: 320px;
      margin-top: 20px;
    }
  }
}
.tips {
  margin-top: 10px;
  font-size: 14px;
  color: red;
}
</style>